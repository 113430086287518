
import Form from '../components/Form/Form';
function ContactUs() {
	return (
		<>
			<Form />
		</>
	);
}

export default ContactUs;
