import React from 'react';
import {
	FooterRights,
	FooterWrapper,
} from './FooterStyles';
import { Section } from '../../globalStyles';

function Footer() {
	return (
		<Section padding="4rem 0 2rem 0">
			<FooterWrapper>

				<FooterRights>Leedveen © 2022</FooterRights>
			</FooterWrapper>
		</Section>
	);
}

export default Footer;
