export const heroOne = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Founded in 2010",
  },
  headline: "WHO WE ARE",
  description:
    "LeedVeen is a company with many diversifications. Our business principle is based on the values of honesty, transparency and respect for our clients. We are committed to delivering the highest quality products and services and these have kept us going and will keep us going in years to come. Our clients are at the centre of every conversation because the satisfaction of our clients is at the peak of our priority. Our main aim is to provide accessible and affordable products and services as we invest in new technologies to make this possible. We make every effort to have an understanding of our clients’ needs and offer services beyond their expectations. In any of the services our clients request, we ensure that they receive value for money.",
  buttonLabel: "Find More",
  imgStart: "start",
  img: "./assets/svg/Deal.svg",
  start: "true",
};

export const heroTwo = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "",
  },
  headline: "OUR MISSION ",
  description:
    "We are on a mission to provide excellent services and create value in order to enhance standards of living globally. We deliver high quality services to our clients. Outstanding customer services is the trademark of a successful business. We balance the idea and expectations of our clients. At LeedVeen, we maintain a sense of honesty and transparency because good relationships are built on trust. It is our responsibility to keep our promise of serving you better even if it takes extra efforts. We have a team of professionals who work together cohesively  to help you achieve your goals.",
  buttonLabel: "Read More",

  linkTo: "/download",
  imgStart: "",
  img: "./assets/svg/ChartUp.svg",
  start: "true",
};
