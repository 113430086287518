export const data = [

	{
		to: '/',
		text: 'Home',
		id: '',
	},
	
	{
		to: '/',
		text: 'About',
		id: 'about',
	},

	{
		to: '/contact',
		text: 'Contact',
	},
];
