import React from 'react';

import { BsHouseDoorFill } from 'react-icons/bs';
import { BsFillDropletFill } from 'react-icons/bs';
import { BsFillBrightnessHighFill } from 'react-icons/bs';
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const featuresData = [
	{
		name: 'REAL ESTATE',
		description: 'We build, manage and develop real estates with dedication to offering our clients high quality results, durable and affordable services.',
		icon: iconStyle(BsHouseDoorFill),
		imgClass: 'one',
	},
	{
		name: 'OIL AND GAS',
		description: 'At LeedVeen, we aspire to enhance energy as we work to make energy more accessible to the community.',
		icon: iconStyle(BsFillDropletFill),
		imgClass: 'two',
	},
	{
		name: 'AGRICULTURE',
		description: 'We offer services such as production of agricultural commodities, management, marketing and distribution. ',
		icon: iconStyle(BsFillBrightnessHighFill),
		imgClass: 'three',
	},
	
];
